body {
  font-family: "Roboto", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  padding: 0;
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
}

.header__box-shadow {
  background-color: #fff;
  color: rgb(50, 50, 50);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0;
  border-radius: 4px;
  box-shadow:
    0 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0 rgba(0, 0, 0, 0.14),
    0px 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  margin-bottom: 0.2em;
  border-radius: 0;
  box-shadow:
    0 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0 rgb(0 0 0 / 14%),
    0px 1px 5px 0 rgb(0 0 0 / 12%);
}

.header__wrapper {
  width: 100%;
  margin-left: auto;
  box-sizing: border-box;
  margin-right: auto;
  display: block;
  padding: 8px 16px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media (min-width: 600px) {
  .header__wrapper {
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media (min-width: 1200px) {
  .header__wrapper {
    max-width: 1200px;
  }
}
@media (min-width: 600px) {
  .header__wrapper {
    height: 58px;
  }
}

*,
::after,
::before {
  box-sizing: inherit;
}
*,
::after,
::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.header__logo {
  margin: 0;
  color: #39a949;
  -webkit-text-decoration: underline;
  text-decoration: underline;
  text-decoration-color: currentcolor;
  text-decoration-color: rgba(57, 169, 73, 0.4);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.header__logo img,
.header__logo svg {
  width: 115px;
}
@media (max-width: 599.95px) {
  .header__logo img,
  .header__logo svg {
    width: 100px;
  }
}

.contact__phone {
  -webkit-text-decoration: none;
  text-decoration: none;
  color: rgb(50, 50, 50);
  -webkit-transition: color 0.25s;
  transition: color 0.25s;
}

.contact__phone:hover {
  color: rgb(116, 196, 127);
}

.contact__phone__text {
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-left: 12px;
}

.contact__phone__text_number {
  margin: 0;
  font-family: "Roboto", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  font-size: 14px;
}

.contact__phone {
  -webkit-text-decoration: none;
  text-decoration: none;
  color: rgb(50, 50, 50);
  -webkit-transition: color 0.25s;
  transition: color 0.25s;
}
.contact__phone__text_label {
  margin: 0;
  font-family: "Roboto", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  font-size: 14px;
  color: #5b5b5b;
}
.contact__phone__button {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  text-align: center;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50% !important;
}
.contact__phone__button:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.contact__phone__svg {
  fill: #848484;
  height: 24px;
  width: 24px;
}
.contact__wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

a {
  text-decoration: none;
  background-color: transparent;
  color: #39a949;
}
