body {
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  font-family: Roboto, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.header__box-shadow {
  color: #323232;
  -webkit-transition: box-shadow .3s cubic-bezier(.4, 0, .2, 1) 0;
  transition: box-shadow .3s cubic-bezier(.4, 0, .2, 1) 0;
  background-color: #fff;
  border-radius: 0;
  margin-bottom: .2em;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px rgba(0, 0, 0, .14), 0 1px 5px rgba(0, 0, 0, .12);
}

.header__wrapper {
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 8px 16px;
  display: flex;
}

@media (min-width: 600px) {
  .header__wrapper {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (min-width: 1200px) {
  .header__wrapper {
    max-width: 1200px;
  }
}

@media (min-width: 600px) {
  .header__wrapper {
    height: 58px;
  }
}

*, :after, :before {
  box-sizing: inherit;
  box-sizing: border-box;
}

.header__logo {
  color: #39a949;
  cursor: pointer;
  align-items: center;
  margin: 0;
  -webkit-text-decoration: underline rgba(57, 169, 73, .4);
  text-decoration: underline rgba(57, 169, 73, .4);
  display: flex;
}

.header__logo img, .header__logo svg {
  width: 115px;
}

@media (max-width: 599.95px) {
  .header__logo img, .header__logo svg {
    width: 100px;
  }
}

.contact__phone:hover {
  color: #74c47f;
}

.contact__phone__text {
  flex-direction: column;
  margin-left: 12px;
}

.contact__phone__text_number {
  margin: 0;
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
}

.contact__phone {
  color: #323232;
  -webkit-text-decoration: none;
  text-decoration: none;
  transition: color .25s;
}

.contact__phone__text_label {
  color: #5b5b5b;
  margin: 0;
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.43;
}

.contact__phone__button {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  color: rgba(0, 0, 0, .54);
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  border-radius: 50%;
  outline: 0;
  flex: none;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 8px;
  font-size: 1.5rem;
  -webkit-text-decoration: none;
  text-decoration: none;
  transition: background-color .15s cubic-bezier(.4, 0, .2, 1);
  display: inline-flex;
  position: relative;
  overflow: visible;
  border-radius: 50% !important;
}

.contact__phone__button:hover {
  background-color: rgba(0, 0, 0, .04);
}

.contact__phone__svg {
  fill: #848484;
  width: 24px;
  height: 24px;
}

.contact__wrapper {
  align-items: center;
  display: flex;
}

a {
  color: #39a949;
  background-color: rgba(0, 0, 0, 0);
  text-decoration: none;
}

/*# sourceMappingURL=index.8852453d.css.map */
